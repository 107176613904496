
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { defineComponent } from 'vue';
import axios from 'axios'

export default defineComponent({
  name:"HomeView",
  data(){
    return{
      //
    }
  },
  mounted(){
    //
  },
  methods: {
    //|
  }

})

